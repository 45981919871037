@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h1, h2, h3, h4, h5, h6, p, span, div, button, input, li > label {
  font-family: 'Lato', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
