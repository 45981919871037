.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cell > button {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

@media (max-width: 991px) {
  .custom-container {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }

  .cell > button {
    opacity: 1;
    transition: 0.2s ease-in-out;
  }
}

@media (min-width: 992px) {
  .custom-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
}

.custom-table > .content > article:hover .cell > button {
  opacity: 1;
}

.alert {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 440px !important;
  width: max-content !important;
}

.alert .message {
  font-size: 16px !important;
}
.container-table-tab > {
  min-height: 100%;
}

.edit-modal > .frame,
.report-modal > .frame {
  padding: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
